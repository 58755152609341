import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';

import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";
import NavDropdown from 'react-bootstrap/NavDropdown';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import { ClienteContext } from "../../../Context/ClienteContext";
import ComponentPagination from "../../Pagination";

const TableEmbarcacaoMain = (props) => {

    const { dadosEmbarcacao, setDadosEmbarcacao } = useContext(EmbarcacaoContext)
    const { currentPage, setCurrentPage } = useContext(ClienteContext);

    const navigate = useNavigate();

    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState();
    const [mensalistasAtivas, setMensalistasAtivas] = useState(0)


    useEffect(() => { setDataTable(props?.dados) }, [props.dados]); //passar resultado pesquisa para table

    useEffect(() => {
        if (Object.keys(filtrosAplicados).length > 0) {
            aplicarFiltro();
        }
        if (Object.keys(filtrosAplicados).length === 0 && props.type === "main") {
            aplicarFiltro();
        }
    },
        [filtrosAplicados, currentPage]
    );

    useEffect(() => { }, [dataTable])

    function RedirectTo(paramters, rote) {
        let finalRote = rote;

        const newDataEmbarcacao = { ...paramters, isReadOnly: 1 };
        setDadosEmbarcacao(newDataEmbarcacao);

        if (rote !== "Checklist") {
            return navigate(finalRote)
        }

        if (rote === "Checklist") {

            if (rote === "Checklist" && paramters.Checklist === null) {
                return;
            }

            console.log('paramters?.Checklist?.statusCheckList = ', paramters?.Checklist?.statusCheckList);
            if (paramters?.Checklist?.statusCheckList === "Pendente") {
                finalRote = "/PageNovoCheckList"
            }

            if (paramters?.Checklist?.statusCheckList === "OK") {
                finalRote = "/detalharChecklist"
            }
        }
        return navigate(finalRote);
    }

    function atualizaFiltros(novofiltro) {
        setFiltrosAplicados(filtrosAplicados => ({ ...filtrosAplicados, ...novofiltro }));
    }

    async function aplicarFiltro() {
        const dadosFiltrados = await RequisicaoPost(`/getAllEmbarcacoesFilter/?page=${currentPage}&size=50`, filtrosAplicados);
        setDataTable(dadosFiltrados?.listaEmbarcacoesFiltradas?.rows, dadosFiltrados?.mensalistasAtivas);
        setMensalistasAtivas(dadosFiltrados?.mensalistasAtivas)
    }

    return (

        <>
            <div>
                <h4>
                    <strong>Mensalistas Ativas: {mensalistasAtivas}</strong>
                </h4>

            </div>

            <hr />
            <p>Filtros Aplicados: {Object.values(filtrosAplicados)}</p>
            <Button variant="secondary" size="sm"
                onClick={() => {
                    setFiltrosAplicados(filtro => ({}));
                    console.log('1- filtros aplicados =', filtrosAplicados);
                    setCurrentPage(page => (0));
                    console.log('2- Current Page = ', currentPage);
                    aplicarFiltro(filtro => ({}));
                    console.log('3- filtro = ', dataTable)
                }
                }
            >
                Limpar Filtros
            </Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Código Emb</th>
                        <th>Data Entrada</th>
                        <th>Embarcacão/Modelo</th>
                        <th>Proprietário</th>
                        <th>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Tipo"
                                menuVariant="dark"
                            >

                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipo: 'Jet-Ski' })}>Jet-Ski</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipo: 'Lancha' })}>Lancha</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipo: 'Barco Pesca' })}>Barco Pesca</NavDropdown.Item>

                            </NavDropdown>
                        </th>
                        <th>Tamanho</th>
                        <th>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Contrato"
                                menuVariant="dark"
                            >

                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipoContrato: 'Mensal' })}>Mensal</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipoContrato: 'Avulso' })}>Avulso</NavDropdown.Item>

                            </NavDropdown>
                        </th>
                        <th>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Status"
                                menuVariant="dark"
                            >

                                <NavDropdown.Item onClick={() => atualizaFiltros({ status: 'Ativo' })} >Ativo</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ status: 'Inativo' })}>Inativo</NavDropdown.Item>

                            </NavDropdown>
                        </th>
                        
                    </tr>
                </thead>

                <tbody>

                    {dataTable?.map((item) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.id}</td>
                            <td>{item?.dataEntradaEmb?.slice(0, 10)/*new Date(item?.dataEntradaEmb).toLocaleDateString()*/}</td>
                            <td>{item?.nome} {item?.modeloCascoEmb}</td>
                            <td>{item?.Cliente?.nome}</td>
                            <td>{item?.tipo}</td>
                            <td>{item?.tamanho}</td>
                            <td>{item?.tipoContrato}</td>
                            <td>{item?.status}</td>
                            {/*<td onClick={(event) => RedirectTo(item, "Checklist")}>
                                <Button variant={item?.Checklist?.statusCheckList === "OK" ? "success" : (item?.Checklist?.statusCheckList === "Pendente" ? "danger" : (item?.Checklist?.statusCheckList === "-" ? "secondary" : ""))}>
                                    {item?.Checklist === null ? " - " : item?.Checklist?.statusCheckList}
                                </Button>
                            </td>*/}
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item, props.roteDetalhar)}>+</Button></td>

                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )


}

export default TableEmbarcacaoMain;